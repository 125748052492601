import { Heading } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { Container } from '../components/Layout/GlobalStyles';

const Wrapper = styled.div`
	margin-top: '10.7rem';
`;

const ContentWrapper = styled.div`
	height: 100%;
	max-width: 780px;
	margin: 0 auto;
	padding: 35px;
	background: #fff;
	box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 13%);
	border-radius: 6px;
	position: relative;
	bottom: 120px;

	p {
		display: flex;

		img {
			max-width: 220px;
			padding: 8px;
		}
	}

	@media ${(props) => props.theme.breakpoint.mobile} {
		padding: 25px 0 25px 0;
		p {
			flex-direction: column;
			align-items: center;
		}
	}
`;


const BrandSpradling  = ({data}) => {
	const brand = data.brands.nodes[0];

    return (
			<Wrapper>
				<StaticImage
					src="../images/dsc9240-1024x683.jpg"
					alt="Spradling"
					style={{ height: '450px', width: '100%' }}
				/>
				<ContentWrapper>
					<Container>
						<Heading as="h1" size="xl" align="center" mb={20}>
							{brand.title}
						</Heading>
						<ReactMarkdown
							className="line-break"
							transformImageUri={(uri) =>
								uri.startsWith('http')
									? uri
									: `${process.env.BASE_URL}${uri.replace('uploads', 'static')}`
							}
						>
							{brand.content}
						</ReactMarkdown>
					</Container>
				</ContentWrapper>
			</Wrapper>
		);
}

export const query = graphql`
	query ($lang: String!, $slug: String!) {
		brands: allStrapiBrands(
			filter: { locale: { eq: $lang }, slug: { eq: $slug } }
		) {
			nodes {
				...brandFields
			}
		}
	}
`;

export default BrandSpradling;